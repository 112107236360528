import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: {
      path: '/VRView',

      name:'VRView',
      // query:{currentScene:"S5",currentId:0}
    },
  },
  {
    path: '/VRView',
    name: 'VRView',
    params:"",
    component: () => import('@/views/components/PanoComp.vue')
  },
  {
    path: '/MapView',
    name: 'MapView',
    params:"",
    component: () => import('@/views/components/2dmapcontrol/MapView.vue')
  },
  {
    path: '/ContentView',
    name: 'ContentView',
    params:"",
    component: () => import('@/views/components/contentpublish/PublishView.vue')
  },
  {
    path: '/wxapp',
    name: 'wxapp',
    params:"",
    component: () => import('@/views/components/wxapp.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory('./'),
  routes
})

export default router
