<template>
    <van-row style="height: 100vh;">
        <el-row class="header">
            <el-col :span="6">
                <a class="href-wxapp" :href="wxapp" target="_blank">精微网格</a>
<!--                <el-button style="background: transparent;color: white" @click="wxapp()">打开小程序</el-button>-->
            </el-col>
            <el-col :span="12" style="text-align: center;margin-top: -0.5rem;color: white">
                <H3>金牛大脑720全景</H3>
            </el-col>
            <el-col :span="6" style="text-align: right">
                <el-button style="background: transparent;border:none;color: white" @click="drawer = true">菜单</el-button>
            </el-col>
            <el-drawer v-model="drawer"
                       :with-header="false"
                       :size='"45%"'
                       style="background: transparent">
                <el-row span="10">
                    <h3 style="color:#ffffff;">全景场景选择</h3>
                </el-row>
                <el-row style="margin-bottom: 0.8rem">
                    <el-button item="1-1" @click="panoScence('1-1')">赵镕将军纪念馆</el-button>
                </el-row>
                <el-row style="margin-bottom: 0.8rem">
                    <el-button @click="panoScence('1-2')">侨史馆</el-button>
                </el-row>
                <el-row style="margin-bottom: 0.8rem">
                    <el-button @click="panoScence('1-3')">金牛镇政府</el-button>
                </el-row>
                <el-row style="margin-bottom: 0.8rem">
                    <el-button @click="panoScence('1-4')">万亩葡萄园</el-button>
                </el-row>
                <el-row style="margin-bottom: 0.8rem">
                    <el-button @click="panoScence('1-5')">万亩柑橘园</el-button>
                </el-row>

            </el-drawer>
        </el-row>
        <router-view class="content" v-if="flag"></router-view>
    </van-row>

</template>

<script>


import axios from "axios";
import {menuListUrl} from "@/api/api";
import wx from "weixin-js-sdk";

export default {
    name: '',
    data() {
        return {
            activeIndex: "1",
            flag: false,
            titletxt: require('@/assets/titletxt1.png'),
            resizeFn: null,
            menuTree: [],
            drawer: false
        }
    },
    mounted() {
        window.addEventListener('resize', this.resizeFn);
        this.flag = true
        this.menuTree.length === 0 ? this.getMenuList() : ""
    },
    computed: {
        wxapp() {
            return `weixin://dl/business/?appid=wx00e03fbaa435e490&path=pages/home/index`;
        },
    },
    methods: {
        onClickLeft() {
        },
        onClickRight() {
        },
        getMenuList() {
            const that = this;
            axios.get(menuListUrl).then(res => {
                if (res.data.code == 2000) {
                    let rst = res.data.data.data
                    //ES6 新的语法，找到数组对象中不重复的数据
                    let farthermenu = [...new Set(rst.map(item => item.fathermenu))]
                    for (let i = 0; i < farthermenu.length; i++) {
                        let child = []
                        rst.map((item, index) => {
                            if (farthermenu[i] === item.fathermenu)
                                child.push({
                                    id: i + 5 + "-" + index,
                                    fathermenu: item.fathermenu,
                                    menuname: item.frontmenuname,
                                    menutype: item.menutype
                                })
                        })
                        that.menuTree.push({id: i + 5, fathermenu: farthermenu[i], children: child})
                    }


                    console.log(this.menuTree)

                } else {
                    this.$message.warning(res.msg)
                }
            })
        },
        panoScence(name) {
            let currentScene, currentId;
            this.menuTree.map((item, index) => {
                item.children.map(childitem => {
                    if (childitem.id === name) {
                        if (childitem.menutype === "地图") {
                            this.$router.push({
                                name: 'MapView',
                                query: {
                                    currentScene:
                                        childitem.fathermenu + "*" + childitem.menuname
                                }
                            });
                        } else if (childitem.menutype === "图文") {
                            this.$router.push({
                                name: 'ContentView',
                                query: {
                                    currentScene:
                                        childitem.fathermenu + "*" + childitem.menuname
                                }
                            });
                        }
                    }
                })
            })
            switch (name) {
                case "1-1":
                    currentScene = "S1";
                    currentId = 0;
                    //一进入就是全景视频的，id由组件来定，video规定id顺序在最后一个
                    this.$router.push({
                        name: 'VRView',
                        query: {currentScene: currentScene, currentId: currentId}
                    });
                    break;
                case "1-2":
                    currentScene = "S2";
                    currentId = 0;
                    this.$router.push({
                        name: 'VRView',
                        query: {currentScene: currentScene, currentId: currentId}
                    });
                    break;
                case "1-3":
                    currentScene = "S3";
                    currentId = 0;
                    this.$router.push({
                        name: 'VRView',
                        query: {currentScene: currentScene, currentId: currentId}
                    });
                    break;
                case "1-4":
                    currentScene = "S4";
                    currentId = 0;
                    this.$router.push({
                        name: 'VRView',
                        query: {currentScene: currentScene, currentId: currentId}
                    });
                    break;
                case "1-5":
                    currentScene = "S5";
                    currentId = 0;
                    this.$router.push({
                        name: 'VRView',
                        query: {currentScene: currentScene, currentId: currentId}
                    });
                    break;
                // case "2-1":
                //     this.$router.push({
                //         name: 'ContentView',
                //         query: {currentScene: "村集体经济*农贸市场"}
                //     });
                //     break;
                // case "2-2":
                //     this.$router.push({
                //         name: 'MapView',
                //         query: {currentScene: "村集体经济*20个代表村经济"}
                //     });
                //     break;
                // case "3-1":
                //     this.$router.push({
                //         name: 'MapView',
                //         query: {currentScene: "基层治理*空中有声音"}
                //     });
                //     break;
                // case "3-3":
                //     this.$router.push({
                //         name: 'MapView',
                //         query: {currentScene: "基层治理*地面有部队"}
                //     });
                //     break;
                // case "3-2":
                //     this.$router.push({
                //         name: 'ContentView',
                //         query: {currentScene: "基层治理*手中有系统"}
                //     });
                //     break;
                // case "4":
                //     this.$router.push({
                //         name: 'ContentView',
                //         query: {currentScene: "金牛铁军"}
                //     });
                //     break;
                // case "5":
                //     this.$router.push({
                //         name: 'ContentView',
                //         query: {currentScene: "人大代表"}
                //     });
                //     break;
            }


            //name==="1-1"?this.$router.push("/VRView"):name==="1-2"?this.$router.push("/VRVideo"):this.$router.push("/")

        }
    },
}
</script>

<style lang="less">
.menu {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  bottom: 50px;
  width: 100%;
  z-index: 99999
}
.href-wxapp{
    line-height: 30px;
    padding: 10px;
    text-align: center; /* 设置文本水平居中 */
    text-decoration: none; /* 移除下划线 */
    background-color: rgba(255, 255, 255, 0);
    color: white
}
.header {
  position: fixed;
  display: flex;
  height: 5vh;
  z-index: 9999999;
  background-color: rgba(7, 7, 236, 0.2);
  width: 100%;

}

.content {
  height: 8rem;
  width: 100%;
}

.page {
  width: 100%;
  height: 100%;
}

</style>
