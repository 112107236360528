<template>
    <MenuBar class="menu-top"></MenuBar>
</template>
<script>
import MenuBar from "@/views/MenuBar.vue";
export default {
    name: 'App',
    components: { MenuBar},
    data() {
        return {
            resizeFn: null
        }
    },
    mounted() {
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.resizeFn);
    }
}
</script>
<style lang="less">
html,body {
  height: 100%;
  width: 100%;
  padding:0;
  margin:0;
}
html {
  font-size: 15px;
}
body {
  transform-origin: left top;
  background-size: 100% 100%;
}

.menu-top {
    position: absolute;
    pointer-events: auto;
    top: 0;
    left: 0;
    width: 100%;
    height: 10vh;
    z-index: 0;
}
.map {
    position: absolute;
    pointer-events: auto;
    top: 10vh;
    width: 100%;
    height: 90vh;
}
#app {
  height: 100%;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>